import { AuthConfig } from '@auth0/auth0-angular'

import { Auth0UriChecker } from '../app/core/services/auth0-uri-checker'

export const Auth0Environment: AuthConfig = {
    clientId: 'N6WsLddUC32JFlYt9aukcSOdn4Ou5ccz',
    domain: 'login.poweredbyliquid.com',
    redirectUri: 'https://app.poweredbyliquid.com/auth-callback',
    audience: 'https://api.poweredbyliquid.com',
    scope: 'read:current_user',
    httpInterceptor: {
        allowedList: [
            {
                uriMatcher: (uri) => {
                    return uri.indexOf('youUp') < 0 && !Auth0UriChecker.isPublicUri(uri) // don't add bearer token if youUp
                },
                tokenOptions: {
                    audience: 'https://api.poweredbyliquid.com',
                    scope: 'read:current_user',
                },
            },
        ],
    },
}

export const environment: any = {
    production: true,
    hmr: false,
    host: 'https://app.poweredbyliquid.com',
    stripeSettings: {
        stripe_dash_logout_redirect: 'https://app.poweredbyliquid.com/liquid/organization-settings/getpaid',
        redirect_uri: 'https://app.poweredbyliquid.com/liquid/payments/stripe-finalize',
        stripe_connect_client_id: 'ca_EC4a3Zb9JJPwSEwkLF7RmYTiCfRmEqcO',
    },
    paymentSettings: {
        ccThreshold: 50,
        hpfUrl: 'https://www.chasepaymentechhostedpay.com/hpf/1_1/?uID=',
    },
    plaidSettings: {
        environment: 'production',
        publicKey: '0a46c0f14f4070fc1ab945bc15f69a',
        webhookUrl: 'https://api.poweredbyliquid.com/plaid-webhooks',
    },
    liquidApiSettings: {
        apiServicePrefix: 'https://api.poweredbyliquid.com',
        orgAvatarPrefix: 'https://org-img.poweredbyliquid.com',
        userAvatarPrefix: 'https://user-img.poweredbyliquid.com',
    },
    liquidIntegratorSettings: {
        qb_link_url: 'https://integrator.poweredbyliquid.com/QBLogin',
    },
    logging: {
        apiKey: 'f19bc42dcfd0fe715f9f7af24c28acbf',
        consoleBreadcrumbsEnabled: true,
        releaseStage: 'prod',
    },
    support: {
        chat: {
            enabled: false,
        },
    },
    internalEmailAdress: {
        compliance: 'compliance@poweredbyliquid.com',
        onboardingSupport: 'onboardingsetuphelp@poweredbyliquid.com',
        support: 'support@poweredbyliquid.com',
    },
    internalSettings: {
        enableNewWOVendorInvite: true,
        enableBulkCreateInvoice: true,
        enableBulkOfflineContracts: false,
        enabledGoogleAnalytics: [
            // 'G-J0QQWJ2BJ M', disabling this for now LQD-10456
            // 'UA-128739751-5',
        ],
        enableFullStory: true,
        enableHubspot: false,
        enableInvoicesWithoutClients: true,
        enableJpmMicrodeposits: false,
        optionalWorkOrderVendor: false,
        enableGuestClient: false,
        enableContacts: false,
        enableCountersigning: true,
        enableNonServiceVendors: true,
        enableDashboardWidgets: true,
        enableGuestCheckout: true,
        enableVendorLedFlow: true,
        enableZendesk: false,
        enableSleekPlan: false,
    },
    liquidStatusUrl: 'https://support.poweredbyliquid.com/hc/',
    liquidUrl: 'https://www.poweredbyliquid.com/',
    googleTagManager: {
        enabled: true,
        // id: 'GTM-N5CS5DJ',
        id: 'GTM-PGJNCLN',
    },
    sleekPlanSettings: {
        productId: '617258481',
    },
}
